import { AppConsts } from '@shared/AppConsts';
import * as i0 from "@angular/core";
export class FileDownloadService {
    downloadTempFile(file) {
        const url = AppConsts.remoteServiceBaseUrl + '/api/File/DownloadTempFile?fileType=' + file.fileType + '&fileToken=' + file.fileToken + '&fileName=' + file.fileName;
        location.href = url;
        // TODO : This causes reloading of same page in Firefox
    }
    pdfExportFile(file) {
        debugger;
        const url = AppConsts.remoteServiceBaseUrl + '/api/File/DownloadPdfFile?fileType=' + file.fileType + '&fileToken=' + file.fileToken + '&fileName=' + file.fileName;
        // location.href = url;
        window.open(url, '_blank');
        // TODO : This causes reloading of same page in Firefox
    }
}
FileDownloadService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FileDownloadService_Factory() { return new FileDownloadService(); }, token: FileDownloadService, providedIn: "root" });
