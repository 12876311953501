import { OrdersDto, OrderItemDto } from '@shared/service-proxies/service-proxies';
import * as _ from 'lodash';

export class PopupHelper {
    static order: OrdersDto;
    static async getOrderInfo(): Promise<OrdersDto> {
        if (PopupHelper.order) {
            return PopupHelper.order;
        }
        const json = await localStorage.getItem(PopupHelper.orderStorageKey);
        if (json && "undefined" != json) {
            return JSON.parse(json);
        }
        return undefined;
    }

    static async updateOrderInfo(ordersDto: OrdersDto): Promise<OrdersDto> {
        PopupHelper.order = ordersDto;
        const json = await localStorage.setItem(PopupHelper.orderStorageKey, ordersDto && JSON.stringify(ordersDto));
        return ordersDto;
    }
    private static orderStorageKey = 'CurrentOrder';

    isOpened: boolean;
    order: OrdersDto;
    loading: boolean;
    opening: (o: OrdersDto) => void = (o) => { };

    open(order?: OrdersDto) {
        this.opening(order);
        this.isOpened = true;
        this.order = order || this.order;
        if (!this.order || _.isEmpty(this.order)) {
            PopupHelper.getOrderInfo().then(o => {
                this.order = o;
            }).catch(a => {
                this.order = new OrdersDto();
                console.log(a);
            });
        } else {
            PopupHelper.updateOrderInfo(order).then(o => {
                console.log('updated order');
            }).catch(a => {
                console.log(a);
            });
        }
    }
    getOrderItemsCount(): number {
        return this.order && this.order.items.length;
    }
    removeItem(item: OrderItemDto): void {
        abp.message.confirm(
            'to remove selected product', '',
            (result: boolean) => {
                if (result) {
                    // already selected?
                    this.order.items.splice(this.order.items.indexOf(item), 1);
                }
            }
        );
        if (this.order.items.length === 0) {
            this.isOpened = false;
        }
    }

    getTotalQuantity(): number {
        return this.order && this.order.items && this.order.items.length &&
            this.order.items.map(i => i.quantity).reduce((a, b) => a + b);
    }

    getTotal(): number {
        return this.order && this.order.items && this.order.items.length &&
            this.order.items.map(i => i.quantity * i.unitPrice).reduce((a, b) => a + b)
    }
    addProductToOrder() {
    }
}
