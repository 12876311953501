import { Injectable } from '@angular/core';
import { AppConsts } from '@shared/AppConsts';
import { FileDto } from '@shared/service-proxies/service-proxies';

@Injectable({
  providedIn: 'root'
})
export class FileDownloadService {
  downloadTempFile(file: FileDto) {
    const url = AppConsts.remoteServiceBaseUrl + '/api/File/DownloadTempFile?fileType=' + file.fileType + '&fileToken=' + file.fileToken + '&fileName=' + file.fileName;
    location.href = url;
     // TODO : This causes reloading of same page in Firefox
}

pdfExportFile(file: FileDto) {
  debugger;
  const url = AppConsts.remoteServiceBaseUrl + '/api/File/DownloadPdfFile?fileType=' + file.fileType + '&fileToken=' + file.fileToken + '&fileName=' + file.fileName;
  // location.href = url;
  window.open(url,'_blank');
   // TODO : This causes reloading of same page in Firefox
}
}
