import { Routes } from '@angular/router';
const ɵ0 = () => import("./account/account.module.ngfactory").then(m => m.AccountModuleNgFactory), ɵ1 = { preload: true }, ɵ2 = () => import("./app/app.module.ngfactory").then(m => m.AppModuleNgFactory), ɵ3 = { preload: true };
const routes = [
    { path: '', redirectTo: '/app/home', pathMatch: 'full' },
    {
        path: 'account',
        loadChildren: ɵ0,
        data: ɵ1
    },
    {
        path: 'app',
        loadChildren: ɵ2,
        data: ɵ3
    }
];
export class RootRoutingModule {
}
export { ɵ0, ɵ1, ɵ2, ɵ3 };
