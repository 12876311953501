var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { OrdersDto } from '@shared/service-proxies/service-proxies';
import * as _ from 'lodash';
export class PopupHelper {
    constructor() {
        this.opening = (o) => { };
    }
    static getOrderInfo() {
        return __awaiter(this, void 0, void 0, function* () {
            if (PopupHelper.order) {
                return PopupHelper.order;
            }
            const json = yield localStorage.getItem(PopupHelper.orderStorageKey);
            if (json && "undefined" != json) {
                return JSON.parse(json);
            }
            return undefined;
        });
    }
    static updateOrderInfo(ordersDto) {
        return __awaiter(this, void 0, void 0, function* () {
            PopupHelper.order = ordersDto;
            const json = yield localStorage.setItem(PopupHelper.orderStorageKey, ordersDto && JSON.stringify(ordersDto));
            return ordersDto;
        });
    }
    open(order) {
        this.opening(order);
        this.isOpened = true;
        this.order = order || this.order;
        if (!this.order || _.isEmpty(this.order)) {
            PopupHelper.getOrderInfo().then(o => {
                this.order = o;
            }).catch(a => {
                this.order = new OrdersDto();
                console.log(a);
            });
        }
        else {
            PopupHelper.updateOrderInfo(order).then(o => {
                console.log('updated order');
            }).catch(a => {
                console.log(a);
            });
        }
    }
    getOrderItemsCount() {
        return this.order && this.order.items.length;
    }
    removeItem(item) {
        abp.message.confirm('to remove selected product', '', (result) => {
            if (result) {
                // already selected?
                this.order.items.splice(this.order.items.indexOf(item), 1);
            }
        });
        if (this.order.items.length === 0) {
            this.isOpened = false;
        }
    }
    getTotalQuantity() {
        return this.order && this.order.items && this.order.items.length &&
            this.order.items.map(i => i.quantity).reduce((a, b) => a + b);
    }
    getTotal() {
        return this.order && this.order.items && this.order.items.length &&
            this.order.items.map(i => i.quantity * i.unitPrice).reduce((a, b) => a + b);
    }
    addProductToOrder() {
    }
}
PopupHelper.orderStorageKey = 'CurrentOrder';
