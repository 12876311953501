import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AbpHttpInterceptor } from '@abp/abpHttpInterceptor';

import * as ApiServiceProxies from './service-proxies';

@NgModule({
    providers: [
        ApiServiceProxies.QueuedEmailsServiceProxy,
        ApiServiceProxies.TokenServiceProxy,
        ApiServiceProxies.UserGroupsServiceProxy,        
        ApiServiceProxies.CampaignServiceProxy,
        ApiServiceProxies.EmailTemplateServiceProxy,
        ApiServiceProxies.QRCodeServiceProxy,
        ApiServiceProxies.RoleServiceProxy,
        ApiServiceProxies.SessionServiceProxy,
        ApiServiceProxies.TenantServiceProxy,
        ApiServiceProxies.PageServiceProxy,
        ApiServiceProxies.UserServiceProxy,
        ApiServiceProxies.TokenAuthServiceProxy,
        ApiServiceProxies.AccountServiceProxy,
        ApiServiceProxies.CatalogServiceProxy,
        ApiServiceProxies.ProductServiceProxy,
        ApiServiceProxies.ConfigurationServiceProxy,
        ApiServiceProxies.OrderAppServicesServiceProxy,
        ApiServiceProxies.SettingsServiceProxy,
        { provide: HTTP_INTERCEPTORS, useClass: AbpHttpInterceptor, multi: true }
    ]
})
export class ServiceProxyModule { }
